@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }

  body {
    @apply bg-white font-grotesque text-base font-normal dark:bg-darkFullDark dark:text-darkFullLight md:text-lg;
  }

  .hide-images img {
    @apply hidden;
  }

  .hide-images .hide-image {
    @apply hidden;
  }

  .hide-images .hide-bg-image {
    background-image: none !important;
  }

  .dark .dark-hide-bg-image {
    background-image: none !important;
  }

  .grayscale .gray-scale-red {
    @apply text-white;
  }
  .grayscale .gray-scale-red-diff {
    @apply text-darkGrayPM;
  }

  .grayscale .gray-scale-red-border {
    @apply border-white;
  }

  h1,
  h2,
  h3 {
    @apply font-bold;
  }

  h1 {
    @apply text-h1Mobile md:text-h1Tablet lg:text-h1;
  }

  h2 {
    @apply text-h2Mobile md:text-h2Tablet lg:text-h2;
  }

  h3 {
    @apply text-h3Mobile md:text-h3Tablet lg:text-h3;
  }
  p:not(:last-child) {
    @apply mb-6;
  }

  input:active,
  input:hover,
  input:focus {
    outline: 0;
    outline-offset: 0;
  }
  button {
    @apply transition-all duration-300 ease-in-out;
  }
}

@layer components {
  /* Component styles */
  .btnLink {
    @apply rounded-base border-accentMain bg-accentMain px-6 py-3.5 text-lg font-normal text-white transition-colors;
  }
  .btnLink:hover,
  .btnLink:focus {
    @apply border-accentAdditional bg-accentAdditional;
  }
}

.container {
  @apply max-w-full md:max-w-3xl lg:max-w-7xl;
}

.lightHeroText {
  @apply ml-auto mt-15 max-w-[80%] md:mt-25 md:max-w-[75%] lg:max-w-[65%];
}

.accentText {
  @apply text-accentMain;
}
.square-before::before {
  @apply absolute left-0 top-[3px] block h-4 w-4 -translate-x-[53%] rotate-45 bg-accentMain content-[''];
}

.border-after::after {
  @apply absolute left-0 top-0 h-full border-l border-[#D73C32]  opacity-50 content-[''];
}

.card-excerpt p {
  @apply mb-0;
}

.gm-style img {
  @apply !block;
}

/* pharmaceutical-development HARD GRID STYLES starts here */
.pharmaGrid {
  @apply relative grid gap-y-15 pl-7 pt-15;
}

.pharmaGrid::before {
  @apply absolute left-0 top-0 h-full border-l border-primaryLight content-[''] md:hidden;
}

.pharmaGridItemIndex {
  @apply relative text-xl md:text-h3;
}

.pharmaGridItemIndex::before {
  @apply absolute -left-7 top-1/2 aspect-square w-4 -translate-x-1/2 -translate-y-1/2 rounded-full bg-accentMain content-[''];
}

/* Slider */

/* .slick-track {
  @apply md:!flex md:h-max;
}

.slick-slide {
  @apply md:!h-full md:!flex-grow;
}
.slick-slide > div {
  @apply !h-full;
} */

.hide-images .slick-slide img {
  @apply bg-slate-400 contrast-0;
}

.custom-title {
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 2rem;
}

.custom-title:not(:last-child) {
  margin-bottom: 60px;
}

.card-title:not(:last-child) {
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .pharmaGrid {
    @apply pl-0 pt-0;
    grid-template-columns: repeat(2, 1fr);
  }
  .pharmaGridItem {
    @apply relative grid gap-8 py-16 pr-6;
  }
  .pharmaGridItem:nth-child(2) {
    grid-area: 2/1/3/2;
  }
  .pharmaGridItem:nth-child(3) {
    grid-area: 2/2/3/2;
  }
  .pharmaGridItem:nth-child(4) {
    grid-area: 3/1/3/2;
  }
  .pharmaGridItem:nth-child(5) {
    grid-area: 3/2/3/3;
  }

  .pharmaGridItem:before {
    @apply absolute left-0 top-0 w-full border-t border-primaryLight content-[''];
  }
  .pharmaGridItem:first-child:before {
    width: calc(100% - 28px);
  }

  .pharmaGridItem:after {
    @apply absolute left-0 top-0 aspect-square w-4 -translate-x-1/2 -translate-y-1/2 rounded-full border-t bg-accentMain content-[''];
  }

  .pharmaGridItemIndex::before {
    @apply hidden;
  }

  .custom-title {
    font-size: 3rem;
    line-height: 3.875rem;
  }

  .custom-title:not(:last-child) {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1280px) {
  .pharmaGrid {
    grid-template-columns: repeat(3, 1fr);
  }
  .pharmaGridItem {
    @apply relative grid gap-8 py-16 pr-6;
  }
  .pharmaGridItem:nth-child(2) {
    grid-area: 1/2/2/3;
  }
  .pharmaGridItem:nth-child(3) {
    grid-area: 2/1/3/2;
  }
  .pharmaGridItem:nth-child(4) {
    grid-area: 2/2/3/3;
  }
  .pharmaGridItem:nth-child(5) {
    grid-area: 2/3/3/4;
  }

  .pharmaGridItem:before {
    @apply absolute left-0 top-0 w-full border-t border-primaryLight content-[''];
  }
  .pharmaGridItem:first-child:before {
    width: 100%;
  }
  .pharmaGridItem:nth-child(2):before {
    width: calc(100% - 28px);
  }

  .pharmaGridItem:after {
    @apply absolute left-0 top-0 aspect-square w-4 -translate-x-1/2 -translate-y-1/2 rounded-full border-t bg-accentMain content-[''];
  }

  .pharmaGridItemIndex::before {
    @apply hidden;
  }

  .custom-title:not(:last-child) {
    margin-bottom: 100px;
  }

  .footer-alert {
    margin-left: 15.5% !important;
  }
}
